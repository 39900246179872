import { render, staticRenderFns } from "./FeedbackList.vue?vue&type=template&id=b6663234&scoped=true&"
import script from "./FeedbackList.vue?vue&type=script&lang=js&"
export * from "./FeedbackList.vue?vue&type=script&lang=js&"
import style0 from "./FeedbackList.vue?vue&type=style&index=0&id=b6663234&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6663234",
  null
  
)

export default component.exports