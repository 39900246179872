<template>
  <!-- 反馈详情页面 -->
  <div class="positionT0L0">
    <p class="title"><span></span> 基本信息</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="账号">
        <span>{{ form.userName }}</span>
      </a-form-model-item>
      <a-form-model-item label="头像">
        <img v-viewer :src="form.imageUrl" class="avatar" />
      </a-form-model-item>
      <a-form-model-item label="昵称">
        <span>{{ form.nickName }}</span>
      </a-form-model-item>
      <a-form-model-item label="性别">
        <span v-show="form.sex == 1">男</span>
        <span v-show="form.sex == 2">女</span>
        <span v-show="form.sex == 3">保密</span>
      </a-form-model-item>
      <a-form-model-item label="类型">
        <span v-show="form.type == 1">优化建议</span>
        <span v-show="form.type == 2">投诉</span>
        <span v-show="form.type == 3">线上bug</span>
        <span v-show="form.type == 4">其他</span>
      </a-form-model-item>
      <a-form-model-item label="内容">
        <span>{{ form.content }}</span>
      </a-form-model-item>
      <a-form-model-item label="联系方式">
        <span>{{ form.contact }}</span>
      </a-form-model-item>
      <a-form-model-item label="图片">
        <viewer :images="form.imgUrls" v-if="isShowImgs">
          <img
            v-for="(item, index) in form.imgUrls"
            :key="index"
            :src="item"
            class="avatar"
          />
        </viewer>
        <span v-if="!isShowImgs">——</span>
      </a-form-model-item>
      <a-form-model-item label="创建时间">
        <span>{{ form.createTime }}</span>
      </a-form-model-item>
      <a-form-model-item label="处理内容">
        <span>{{ form.handleContent }}</span>
        <a-icon
          v-show="isShowHandle"
          type="edit"
          @click="() => (modalVisible = true)"
          style="margin-left: 10px"
        />
      </a-form-model-item>
    </a-form-model>
    <!-- 处理内容弹框 -->
    <EditModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :handleContent="form.handleContent"
      @cancel="() => (modalVisible = false)"
      @ok="ok"
    />
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button @click="() => $router.go(-1)">返回</a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {
  FeedbackDetailApi,
  FeedbackHandleApi,
} from "@/request/api/userFeedback";
import EditModal from "./modules/EditModal.vue";
import { message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  mixins: [baseMixin],
  components: { EditModal, FooterToolBar },
  created() {
    FeedbackDetailApi({ id: this.$route.params.id }).then(({ code, data }) => {
      if (code == 200) {
        data.imgUrls = data.imgUrls.split(",");
        this.form = data;
        this.isShowImgs = this.form.imgUrls[0] ? true : false;
      }
    });
    if (codeFn("/admin/home/feedback/handle")) this.isShowHandle = true;
  },
  data() {
    return {
      form: {},
      rules: {},
      modalVisible: false, // 弹框是否显示
      isShowHandle: false, // 是否显示编辑的按钮
      isShowImgs: false, // 多个图片是否显示
    };
  },
  methods: {
    // 模态框的确定按钮
    ok(vlaue) {
      if (vlaue) {
        FeedbackHandleApi({
          id: this.$route.params.id,
          content: vlaue,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("编辑成功");
            this.form.handleContent = vlaue;
          }
        });
        this.modalVisible = false;
      } else {
        message.error("内容不能为空");
      }
    },
  },
};
</script>
 
<style lang="less" scoped>
.avatar {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 20px;
}
</style>