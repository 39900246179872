<template>
  <!-- 修改处理内容组件 -->
  <a-modal
    title="用户反馈处理"
    width="50%"
    :visible="modalVisible"
    okText="确定"
    cancelText="取消"
    @ok="ok"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <div class="box">
      <span>处理内容 : </span>
      <a-textarea v-model="inputVal" :maxLength="300" />
      <span class="box_span">{{ inputVal.length }}/300</span>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    handleContent: {
      type: String,
      default: "",
    },
  },
  created() {
    this.inputVal = this.handleContent;
  },
  data() {
    return {
      inputVal: "",
    };
  },
  methods: {
    ok() {
      this.$emit("ok", this.inputVal);
    },
  },
};
</script>
 
<style lang="less" scoped>
.box {
  display: flex;
  position: relative;
  /deep/.ant-input {
    resize: none;
    width: 90%;
    margin-left: 20px;
    height: 150px;
  }
  .box_span {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
}
// /deep/.ant-input {
//   resize: none;
// }
</style>