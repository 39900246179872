<template>
  <!-- 反馈列表页面 -->
  <div class="padding24 color_white" id="feedback_list">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left"></div>
        <div class="top_rigiht">
          <span>
            类型 :
            <a-select
              v-model="appFeedback"
              style="width: 120px"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              @change="changeAppFeedback"
            >
              <a-select-option v-for="item in appFeedbackArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </span>
          <span>
            处理状态 :
            <a-select
              v-model="status"
              style="width: 120px"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              @change="changeStatus"
            >
              <a-select-option v-for="item in statusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </span>

          <a-input
            v-model="searchVal"
            placeholder="请输入账号/昵称/内容"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            icon="search"
            type="primary"
            @click="searchClick"
            :disabled="isDisableSearch"
            >搜索</a-button
          >
        </div>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <img
          v-viewer
          class="avatar"
          slot="imageUrl"
          slot-scope="imageUrl, record"
          :src="record.imageUrl"
        />
        <span slot="imgUrls" slot-scope="imgUrls, record">
          <img
            v-if="record.imgUrls != ''"
            v-viewer
            class="avatar"
            :src="record.imgUrls"
          />
          <span v-if="record.imgUrls == ''" style="margin-left: 10px">——</span>
        </span>
        <span slot="sex" slot-scope="sex, record">
          <span v-show="record.sex == 1">男</span>
          <span v-show="record.sex == 2">女</span>
          <span v-show="record.sex == 3">保密</span>
        </span>
        <span slot="type" slot-scope="type, record">
          <span v-show="record.type == 1">优化建议</span>
          <span v-show="record.type == 2">投诉</span>
          <span v-show="record.type == 3">线上bug</span>
          <span v-show="record.type == 4">其他</span>
        </span>
        <span slot="status" slot-scope="status, record">
          <span v-show="record.status == 0">未处理</span>
          <span v-show="record.status == 1">已处理</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a
            v-show="isShowDetail"
            class="margin_right20"
            @click="detailClick(record.id)"
          >
            详情
          </a>
          <a v-show="isShowHandle" @click="handleClick(record)">处理</a>
        </span>
      </a-table>
      <!-- 处理内容弹框 -->
      <EditModal
        v-if="modalVisible"
        :modalVisible="modalVisible"
        :handleContent="currentRecord.handleContent"
        @cancel="() => (modalVisible = false)"
        @ok="ok"
      />
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { FeedbackListApi, FeedbackHandleApi } from "@/request/api/userFeedback";
import MyPagination from "@/components/pagination/MyPagination";
import EditModal from "./modules/EditModal.vue";
import { message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination, EditModal },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.appFeedback.forEach((item) => this.appFeedbackArr.push(item));
    if (codeFn("/admin/home/feedback/{id}")) this.isShowDetail = true;
    if (codeFn("/admin/home/feedback/handle")) this.isShowHandle = true;
  },
  data() {
    return {
      modalVisible: false, // 模态框的显示隐藏
      pageNo: 1,
      pageSize: 20,
      count: 0,
      searchVal: "", // 输入框的值
      isDisableSearch: false, // 搜索按钮是否禁用
      appFeedback: "", // 类型默认值
      appFeedbackArr: [{ key: "", value: "全部类型" }], //类型的数组
      status: "", // 处理状态默认值
      statusArr: [
        { key: "", value: "全部状态" },
        { key: "0", value: "未处理" },
        { key: "1", value: "已处理" },
      ], // 处理状态数组
      tableLoading: true, // 表格的loading图
      columns: [
        {
          title: "账号",
          dataIndex: "userName",
          width: "8%",
          key: "userName",
        },
        {
          title: "头像",
          dataIndex: "imageUrl",
          key: "imageUrl",
          width: "6%",
          scopedSlots: { customRender: "imageUrl" },
        },
        {
          title: "昵称",
          dataIndex: "nickName",
          key: "nickName",
          width: "8%",
        },
        {
          title: "性别",
          dataIndex: "sex",
          key: "sex",
          scopedSlots: { customRender: "sex" },
          width: "4%",
        },
        {
          title: "类型",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
          width: "6%",
        },
        {
          title: "内容",
          dataIndex: "content",
          width: "30%",
          key: "content",
        },
        {
          title: "联系方式",
          dataIndex: "contact",
          key: "contact",
          width: "8%",
        },
        {
          title: "图片",
          dataIndex: "imgUrls",
          key: "imgUrls",
          width: "6%",
          scopedSlots: { customRender: "imgUrls" },
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "6%",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          sorter: true,
          key: "createTime",
          width: "8%",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      currentRecord: {}, //当前列表
      isShowDetail: false, // 详情是否显示
      isShowHandle: false, // 处理是否显示
    };
  },
  watch: {
    searchVal(newVal) {
      if (newVal.length <= 0) {
        this.FeedbackListFn();
      }
    },
    $route: {
      handler(to) {
        if (to.path == "/userFeedback/feedbackList") {
          this.FeedbackListFn();
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 详情的按钮
    detailClick(id) {
      this.$router.push(`/userFeedback/feedbackList/feedbackDetail/${id}`);
    },
    // 处理的按钮
    handleClick(record) {
      this.currentRecord = record;
      this.modalVisible = true;
    },
    // 模态框的确定按钮
    ok(vlaue) {
      if (vlaue) {
        FeedbackHandleApi({
          id: this.currentRecord.id,
          content: vlaue,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("编辑成功");
          }
        });
        this.modalVisible = false;
      } else {
        message.error("内容不能为空");
      }
    },
    // 类型改变的回调
    changeAppFeedback(value) {
      this.appFeedback = value;
      this.FeedbackListFn();
    },
    changeStatus(value) {
      this.status = value;
      this.FeedbackListFn();
    },
    // 回车和搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.FeedbackListFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.FeedbackListFn();
    },
    // 意见反馈列表函数
    FeedbackListFn() {
      FeedbackListApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        type: this.appFeedback, // 类型
        status: this.status, // 处理状态
        keyword: this.searchVal,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          this.count = data.count;
          data.data.forEach((item) => {
            item.key = item.id;
            item.userName = item.userName == "" ? "——" : item.userName;
            item.nickName = item.nickName == "" ? "——" : item.nickName;
            item.imgUrls = item.imgUrls.split(",")[0];
          });
          this.tableData = data.data;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#feedback_list");
    },
  },
};
</script>
 
<style lang="less" scoped>
#feedback_list {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 20px;
    }
  }
}
.avatar {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>